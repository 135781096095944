<template>
  <div id="login" class="fill-height">
    <v-container fluid class="Login--Container fill-height pa-0">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="6"
          class="Login--LeftSideContainer"
          :style="{
            backgroundImage: `url(${getImage('backformulaire.jpg')})`,
          }"
        >
          <v-row no-gutters align="center" justify="center" class="fill-height">
            <div>
              <v-img
                alt="Logo Action Prevention"
                :src="getImage('logo-white.png')"
                width="50"
              />
            </div>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row no-gutters align="center" class="fill-height">
            <v-col cols="12" class="px-5">
              <v-row no-gutters align="center" class="fill-height">
                <v-col cols="12">
                  <h2>Login</h2>
                  <div class="TitleUnderline ap-background-color mb-4"></div>
                </v-col>
                <v-col cols="12">
                  <SignIn />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SignIn from "@/views/Login/SignIn";

export default {
  name: "Login",
  components: {
    SignIn,
  },
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../styles/Login/login.less";
</style>

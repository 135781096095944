<template>
  <v-form ref="form" class="fill-height">
    <v-text-field
      prepend-inner-icon="mdi-account"
      label="Email"
      :rules="rules.email"
      v-model="email"
      outlined
      validate-on-blur
    ></v-text-field>
    <v-text-field
      label="Mot de passe"
      v-model="password"
      :type="showPassword ? 'text' : 'password'"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="rules.password"
      prepend-inner-icon="mdi-lock-outline"
      outlined
      validate-on-blur
      @click:append="showPassword = !showPassword"
    ></v-text-field>
    <v-checkbox
      v-model="rememberMe"
      label="Se souvenir de moi"
      class="my-0 py-0"
    ></v-checkbox>
    <div v-if="error" class="red--text">{{ error }}</div>

    <v-btn
      color="#37A3C6"
      tile
      large
      class="white--text"
      :loading="loading"
      :class="{
        'fill-width': !$vuetify.breakpoint.mdAndUp,
      }"
      @click="signIn"
    >
      <span> Connexion </span>
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    return {
      email: "",
      password: "",
      rememberMe: true,
      showPassword: false,
      loading: false,
      error: "",
      rules: {
        email: [
          (v) => !!v || "Un email est requis",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "E-mail invalide",
        ],
        password: [(v) => !!v || "Le mot de passe est requis"],
      },
    };
  },
  methods: {
    async signIn() {
      if (this.$refs.form.validate()) {
        this.error = "";
        this.loading = true;
        const loggedIn = await this.$store.dispatch("login/signIn", {
          login: {
            email: this.email,
            password: this.password,
          },
          rememberMe: this.rememberMe,
        });
        this.loading = false;
        if (loggedIn) {
          this.$router.push("/dashboard");
        } else {
          this.error = "Le mail/mot de passe n'est pas valide.";
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/Login/sign-in.less";
</style>

